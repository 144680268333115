
import "./css/xui.css"
import "./css/main.css"

export default () =>
    <div class="app">

        <div class="banner">
            <div class="header-bar">
                <img src="img/logo.jpg" />
                <span>
                    <a href={process.env.ADDRESS_LINK} target="_blank">
                        <i class="fa-solid fa-location-dot"></i>
                        {process.env.ADDRESS_TEXT}
                    </a>
                    <a href={"tel:"+process.env.PHONE_LINK} target="_blank">
                        <i class="fa fa-phone"></i>
                        {process.env.PHONE_TEXT}
                    </a>
                </span>
                <div>
                    <a href={process.env.CREDIT_LINK}>Shop Now</a>
                </div>
            </div>

            <div class="banner-inner" style="background-image: url(img/00.jpg)">
            </div>

            <div class="content ovf-auto">
                <br/>

                <div class="f-row g-3 g-4-md g-6-xl flex-justify-center">
                    <div class="s-3-xl s-3h-lg s-5-md s-9">
                        <a href={process.env.CREDIT_LINK} target="_blank"><img src="img/ad-en.jpg" style:width="100%"/></a>
                    </div>
                    <div class="s-3-xl s-3h-lg s-5-md s-9">
                        <a href={process.env.CREDIT_LINK} target="_blank"><img src="img/ad-es.jpg" style:width="100%"/></a>
                    </div>
                </div>

                <div class="f-row flex-justify-center">
                    <div class="s-10 s-4-xl">
                        <div style="text-align:center; color:#fa0; margin: 3rem 0 1rem 0; font-size:1.2rem;"><i class="fa-solid fa-location-dot"></i> Visit Us</div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3314.8453579127927!2d-116.4662902!3d33.8163036!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80db1d857ee195f3%3A0xfa97f0d6ec01cb3c!2sK%20%26%20E%20Pro%20Tires!5e0!3m2!1sen!2shn!4v1713975196644!5m2!1sen!2shn" style="width: 100%; height: 400px; border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div style="text-align:center; color:#eee; font-size: 1rem;">
                            {process.env.ADDRESS_TEXT}
                        </div>
                    </div>
                </div>

                <div class="carousel p-4 cols-2 p-8-xl cols-5-xl f-row g-2 flex-justify-center flex-items-center">

                    <div class="s-6 s-2_4-xl"><a href="img/09.jpg" target="_blank"><img src="img/09.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/03.jpg" target="_blank"><img src="img/03.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/12.jpg" target="_blank"><img src="img/12.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/13.jpg" target="_blank"><img src="img/13.jpg"/></a></div>

                    <div class="s-6 s-2_4-xl"><a href="img/14.jpg" target="_blank"><img src="img/14.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/15.jpg" target="_blank"><img src="img/15.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/16.jpg" target="_blank"><img src="img/16.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/18.jpg" target="_blank"><img src="img/18.jpg"/></a></div>

                    <div class="s-6 s-2_4-xl"><a href="img/00.jpg" target="_blank"><img src="img/00.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/01.jpg" target="_blank"><img src="img/01.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/02.jpg" target="_blank"><img src="img/02.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/06.jpg" target="_blank"><img src="img/06.jpg"/></a></div>

                    <div class="s-6 s-2_4-xl"><a href="img/04.jpg" target="_blank"><img src="img/04.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/05.jpg" target="_blank"><img src="img/05.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/17.jpg" target="_blank"><img src="img/17.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/21.jpg" target="_blank"><img src="img/21.jpg"/></a></div>

                    <div class="s-6 s-2_4-xl"><a href="img/19.jpg" target="_blank"><img src="img/19.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/20.jpg" target="_blank"><img src="img/20.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/10.jpg" target="_blank"><img src="img/10.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/22.jpg" target="_blank"><img src="img/22.jpg"/></a></div>

                    <div class="s-6 s-2_4-xl"><a href="img/24.jpg" target="_blank"><img src="img/24.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/23.jpg" target="_blank"><img src="img/23.jpg"/></a></div>
                    <div class="s-6 s-2_4-xl"><a href="img/25.jpg" target="_blank"><img src="img/25.jpg"/></a></div>
                </div>

                <br/><br/><br/><br/>
            </div>
        </div>

    </div>
;
